
import { createI18n } from 'vue-i18n';
import store from "@/store";
import router from './router';  // 确保路径正确


const messages = {
    en: require('./locales/en.json'),
    zh: require('./locales/zh.json'),
    fr: require('./locales/fr.json'),
    // 其他语言
    it: require('./locales/it.json'),
    ja: require('./locales/ja.json'),
    hu: require('./locales/hu.json'),
    pl: require('./locales/pl.json'),
    sv: require('./locales/sv.json'),
    de: require('./locales/de.json'),
    nl: require('./locales/nl.json'),
};

const i18n = createI18n({
    locale:store.getters.currentLanguage,
    fallbackLocale: 'zh',
    messages,
});

// 添加 watch 特性
store.watch(
    (state) => state.currentLanguage,
    (newLanguage) => {
        i18n.global.locale = newLanguage;

        // 强制刷新当前路由以更新页面标题
        router.push({ path: router.currentRoute.value.path }).catch(err => {
            if (err.name !== "NavigationDuplicated") {
                throw err;
            }
        });
    }
);

export default i18n;
