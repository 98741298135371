import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './assets/css/publicStyle.css'
import './assets/css/custom-styles.css'; // 引入自定义的CSS文件
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store';
import i18n from '@/i18n';

const app = createApp(App);

// 注册 Element Plus 的图标
for (const [name, icon] of Object.entries(ElementPlusIconsVue)) {
    app.component(name, icon);
}

// 使用 Element Plus 插件
app.use(ElementPlus);

// 在全局上下文中提供 Axios 实例
app.provide('$axios', axios);

app.use(router);
app.use(store);
app.use(i18n);

app.mount('#app');

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}
